<template>
  <div>
    <el-row class="bgW">
      <el-col :span="22">
        <!--检索条件-->
        <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
          <el-form-item label="客户名称">
            <el-input
              v-model="search.customer_name"
              maxlength="30"
              class="w150"
              clearable
              placeholder="请输入客户名称"
            />
          </el-form-item>
          <el-form-item label="活动:" >
             <el-select
               v-model="search.event_id"
               class="w300"
               clearable
               placeholder="请选择商品分类"
             >
               <el-option
                 v-for="item in eventList"
                 :key="item.id"
                 :label="item.name"
                 :value="item.id"
               />
             </el-select>
           </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="2" style="text-align: right;">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
      </el-col>
    </el-row>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="ID" prop="id" />
          <ElTableColumn label="奖品" prop="name" />
          <ElTableColumn label="活动名称" prop="event_name" />
          <ElTableColumn label="客户" prop="customer_name" />
          <ElTableColumn label="抽奖时间" prop="created_at" />
        </el-table>
        <pagination
          class="page tc mt10"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.limit"
          @pagination="getList"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pagination from "@/components/Pagination";
import { getMiniEventList,getPrizeLog } from "@/api/sys";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  customer_name = ""; // 客户名称
  event_id="";
}

export default {
  name: "prizeLogList",
  components: {
    pagination
  },
  data() {
    return {
      page: new Page(), // 分页
      search: new Search(), // 检索条件
      list: [], // 数据展示
      eventList: []
    };
  },
  computed: {
    ...mapGetters(["userInfo"])
  },
  mounted() {
    this.getList();
    this.getEventList();
  },
  methods: {
    // 获取列表
    getList(option) {
      if (option === "search") this.page = new Page();
      getPrizeLog({ ...this.search, ...this.page }).then(res => {
        this.list = res.data.list;
        this.page.total = res.total;
      });
    },
    getEventList(){
      getMiniEventList().then(res => {
        this.eventList = res.data.list;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
