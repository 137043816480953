import axios from '@/utils/axios'

// 首页基础信息
export const readAdminSysHomeInfo = params => axios({
    method: 'get',
    url: '/admin/sys/readAdminSysHomeInfo',
    params
  }).then(res => res.data)

// 首页员工业绩
export const readAdminSysHomePersonAchieve = params => axios({
    method: 'get',
    url: '/admin/sys/readAdminSysHomePersonAchieve',
    params
  }).then(res => res.data)

// 获取系统信息
export const readAdminSysServerInfo = params => axios({
    method: 'get',
    url: '/admin/sys/readAdminSysServerInfo',
    params
  }).then(res => res.data)

// 获取系统登录日志
export const readAdminSysLoginLog = params => axios({
    method: 'get',
    url: '/admin/sys/readAdminSysLoginLog',
    params
  }).then(res => res)

// 系统账户设置
export const updateAdminSysUser = data =>
  axios.post('/admin/sys/updateAdminSysUser', data)
    .then(res => res)

// 获取系统账户信息
export const getAdminSysUserInfo = data =>
  axios.post('/admin/sys/getAdminSysUserInfo', data)
    .then(res => res)

// 小程序首页保存接口
export const setMiniIndex = data =>
  axios.post('/crm/customer/setMiniIndex', data)
    .then(res => res)

// 小程序首页后台回显
export const miniIndex = data =>
  axios.post('/crm/customer/miniIndex', data)
    .then(res => res)

// 小程序用户列表
export const wxList = data =>
  axios.post('/crm/customer/wxList', data)
    .then(res => res)

// 小程序用户列表导出
export const wxExport = data =>
  axios.post('/crm/customer/wxList_export', data)
    .then(res => res)

// 首页预警提示
export const get_number_of_prompts = data =>
  axios.post('/crm/customer/get_number_of_prompts', data)
    .then(res => res)
// 获取服务评价问题列表
export const questionList = data =>
  axios.post('/crm/miniProgram/questionList', data)
    .then(res => res)
// 服务评价
export const evaluationList = data =>
  axios.post('/crm/miniProgram/evaluationList', data)
    .then(res => res)

// 意见反馈
export const feedbackList = data =>
  axios.post('/crm/miniProgram/feedbackList', data)
    .then(res => res)

// 满意度
export const manyiduList = data =>
  axios.post('/crm/miniProgram/manyiduList', data)
    .then(res => res)

// 获取问卷问题
export const getAnswers = data =>
  axios.post('/crm/miniProgram/getAnswers', data)
    .then(res => res)

// 获取轮播列表
export const bannerList = data =>
  axios.post('/crm/miniProgram/getBannerList', data)
    .then(res => res)

// 小程序新增轮播数据
export const bannerUpload = data =>
  axios.post('/crm/miniProgram/addBanner', data)
    .then(res => res)

// 小程序轮播图开关(PC管理)
export const changBannerStatus = data =>
  axios.post('/crm/miniProgram/changBannerStatus', data)
    .then(res => res)
// 小程序抽奖活动列表
export const getMiniEventList = data =>
  axios.post('/crm/miniProgram/getMiniEventList', data)
    .then(res => res)
// 小程序抽奖活动产品列表
export const getMiniEventPrizeDetail = data =>
  axios.post('/crm/miniProgram/getMiniEventPrizeDetail', data)
    .then(res => res)
// 小程序抽奖获取奖品选项
export const getObject = data =>
  axios.post('/crm/miniProgram/getObject', data)
    .then(res => res)
// 小程序抽奖更新奖品
export const updatePrize = data =>
  axios.post('/crm/miniProgram/updatePrize', data)
    .then(res => res)
// 小程序抽奖记录
export const getPrizeLog = data =>
  axios.post('/crm/miniProgram/getPrizeLog', data)
    .then(res => res)
// 小程序抽奖活动列表
export const updateMiniPrizeEvent = data =>
  axios.post('/crm/miniProgram/updateMiniPrizeEvent', data)
    .then(res => res)
// 修改抽奖活动状态
export const updateMiniPrizeEventStatus = data =>
  axios.post('/crm/miniProgram/updateMiniPrizeEventStatus', data)
    .then(res => res)
